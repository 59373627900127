import * as React from "react"

import { Helmet } from "react-helmet"

import HPPLayout from "../../components/layouts/layout-hpp"
import Seo from "../../components/seohp"
import EopCalloutCards from "../../components/shared/generic/eop-callout-cards"
import CalloutIcon1 from "../../images/icons/icon-callout-hp-dosing.png"
import CalloutIcon2 from "../../images/icons/icon-callout-hp-connect.png"
import akbcares from "../../images/AkebiaCares-logo.png"

import VideoInModal from "../../components/shared/modal-video"
import TeamApproach from "../../images/hp/resources/Team-Approach-to-Treating-HP@2x.png"
import MoaVidThumb from "../../images/hp/resources/AKB_Auryxia_MOA_TitleCard.png"
import HPAccessFlashcard from "../../images/hp/resources/AkebiaCares-HP-Access-Flashcard_Thumb.jpg"
import HPQuickHits from "../../images/hp/resources/HP_Quick_Hit_Thumb.png"
import FreetrialFlyerThumb from "../../images/hp/resources/freetrial-flyer-thumb.png"
import FreetrialEnrollmentThumb from "../../images/hp/resources/freetrial-enrollment-thumb.png"
import AuryPatientThumb from "../../images/hp/resources/Digital-HP-Branded-Tearsheet.jpg"
import HPPatientThumb from "../../images/hp/resources/Digital-HP-Unbranded-Patient-Tearsheet.jpg"
import NutritionThumb from "../../images/hp/resources/Food_Label_Digital_Tear_Sheet.jpg"

import AuryPatientHandout from "../../pdfs/Digital_HP_Branded_Tearsheet.pdf"
import HPPatientHandout from "../../pdfs/Digital_HP_Unbranded_Patient_Tearsheet.pdf"
import NutritionHandout from "../../pdfs/Food_Label_Digital_Tear_Sheet.pdf"
//import KidneyVidThumb from "../../images/hp/resources/AKB_Akebia_2021_National-Kidney-Month_Video_02d_Thumbnail.jpg"
import ClinicalFactPDF from "../../pdfs/HP Quick Hit Brochure Update (Digital).pdf"
// import freetrialPDF from "../../pdfs/freetrial.pdf"
import FreeTrialProgramflyerPDF from "../../pdfs/AURYXIA-Free-Trial-Program-flyer.pdf"

const ResourcesPage = ({ location }) => (
    <HPPLayout
        location={location}
        locationHostName={location.hostname}
        jobCode="PP-AUR-US-1407 (v8.0)"
        jobCodeDate="09/24"
        references={[
            <>
                AURYXIA<sup>®</sup> [Package Insert]. Cambridge, MA: Akebia
                Therapeutics, Inc.
            </>,
            <>
                Ganz T, Bino A, Salusky IB. Mechanism of Action and Clinical
                Attributes of Auryxia<sup>&#x00ae;</sup> (Ferric Citrate).{" "}
                <em>Drugs</em>. 2019;79(9):957-968.
                doi:10.1007/s40265-019-01125-w
            </>,
        ]}
    >
        <Helmet>
            <html lang="en" />
            <link
                rel="canonical"
                href="https://www.auryxiahcp.com/hyperphosphatemia/resources"
            />
            <body className="hpp" id="hpp-hcp-resources" />
        </Helmet>

        <Seo
            title="HCP Resources"
            description="Find helpful resources on treatment with AURYXIA® (ferric citrate) tablets, including videos, brochures and more. Read Important Safety Information and Full Prescribing information on this page."
        />
        <section id="hero" className="center">
            <div className="flex-row">
                <div>
                    <h1>Resources for AURYXIA </h1>
                    <p>
                        Find helpful resources, including videos, brochures, and
                        more.
                    </p>
                </div>
            </div>
        </section>

        <section id="resources" className="col-10 center">
            <div className="flex-row">
                <div>
                    <h2 className="hpp">Mechanism of Action</h2>
                    <h2>&nbsp;</h2>
                    <p>
                        Learn how the unique formulation of AURYXIA works in the
                        body to help manage adult patients&#x2019; phosphorus
                        levels.<sup>1,2</sup>
                    </p>
                    {/* <VideoInModal
						accountId="5982844371001"
						playerId="eQq6v3QYZR"
						videoId="6189938633001"
						posterImg={MoaVidThumb}
						altText="Mechanism of Action"
						siteAudience="hpp"
					/> */}
                    <VideoInModal
                        url="https://www.youtube.com/embed/LVGUnE2m7xQ"
                        // width="100%"
                        // height="100%"
                        posterImg={MoaVidThumb}
                        altText="Mechanism of Action"
                        siteAudience="hpp"
                    />
                </div>

                <div>
                    <h2 className="hpp">
                        A Team Approach to Treating Hyperphosphatemia
                    </h2>
                    <p>
                        Find out why these healthcare professionals choose
                        AURYXIA for their adult patients with chronic kidney
                        disease on dialysis.<sup>1</sup>
                    </p>

                    {/* <VideoInModal
						accountId="5982844371001"
						playerId="eQq6v3QYZR"
						videoId="6262901999001"
						posterImg={TeamApproach}
						altText="A Team Approach to Treating Hyperphosphatemia"
						siteAudience="hpp"

					/> */}
                    <VideoInModal
                        url="https://www.youtube.com/embed/YKjUfwxwgdQ"
                        // width="100%"
                        // height="100%"
                        posterImg={TeamApproach}
                        altText="A Team Approach to Treating Hyperphosphatemia"
                        siteAudience="hpp"
                    />
                </div>

                {/* <div>
					<h2 className="hpp">Access Support</h2>
					<p>Explore AURYXIA coverage for hyperphosphatemia.</p>
					<a href="https://www.auryxia.com/wp-content/uploads/AkebiaCares-HP-Access-Flashcard.pdf" className="pdf" target="_blank" rel="noreferrer"><img src={HPAccessFlashcard} alt="Access Support" /></a>
					<a href="https://www.auryxia.com/wp-content/uploads/AkebiaCares-HP-Access-Flashcard.pdf" className="cta hpp" target="_blank" rel="noreferrer"><span>DOWNLOAD</span></a>
				</div> */}

                <div>
                    <h2 className="hpp">AURYXIA Clinical Facts</h2>
                    <p>
                        Find out more about hyperphosphatemia and AURYXIA
                        efficacy, safety, and access information.
                    </p>
                    <a
                        href={ClinicalFactPDF}
                        className="pdf"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={HPQuickHits} alt="AURYXIA Clinical Facts" />
                    </a>
                    <a
                        href={ClinicalFactPDF}
                        className="cta hpp"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <span>DOWNLOAD</span>
                    </a>
                </div>

                <div>
                    <h2 className="hpp">AURYXIA Patient Handbook</h2>
                    <p>
                        Help patients understand hyperphosphatemia and what to
                        expect now that they have been prescribed AURYXIA.
                    </p>

                    <a
                        href={AuryPatientHandout}
                        className="pdf"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img
                            src={AuryPatientThumb}
                            alt="AURYXIA Patient Handout"
                        />
                    </a>
                    <a
                        href={AuryPatientHandout}
                        className="cta hpp"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <span>DOWNLOAD</span>
                    </a>
                </div>

                <div>
                    <h2 className="hpp">Hyperphosphatemia Patient Handout</h2>
                    <p>
                        Give patients the important facts about
                        hyperphosphatemia, why it should be managed, tips for
                        controlling their phosphorus levels, and more.
                    </p>

                    <a
                        href={HPPatientHandout}
                        className="pdf"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img
                            src={HPPatientThumb}
                            alt="AURYXIA Patient Handout"
                        />
                    </a>
                    <a
                        href={HPPatientHandout}
                        className="cta hpp"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <span>DOWNLOAD</span>
                    </a>
                </div>

                <div>
                    <h2 className="hpp">Patient Nutrition Handbook</h2>
                    <p>
                        Help patients understand where phosphorus appears in
                        their meals and how to read nutrition fact labels to
                        find hidden phosphorus in food.
                        <br />
                        &nbsp;
                    </p>

                    <a
                        href={NutritionHandout}
                        className="pdf"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img
                            src={NutritionThumb}
                            alt="AURYXIA Patient Handout"
                        />
                    </a>
                    <a
                        href={NutritionHandout}
                        className="cta hpp"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <span>DOWNLOAD</span>
                    </a>
                </div>

                {/* 
				<VideoInModal
						accountId="5982844371001"
						playerId="eQq6v3QYZR"
						videoId="6259183559001"
						posterImg={KidneyVidThumb}
						altText="Recognizing National Kidney Month"
						siteAudience="hpp" />
				*/}
            </div>
        </section>

        <div className="single_card">
            <EopCalloutCards
                siteAudience="hpp"
                callOneClass="highlight"
                callOneLink={"/hyperphosphatemia/dosing/"}
                callOneContent={
                    <>
                        <img src={CalloutIcon1} alt="" width="98" height="98" />
                        <h3>Ready to prescribe?</h3>
                        <p>
                            The first step to achieve the proven efficacy of
                            AURYXIA is to start with the recommended dose. Find
                            out how to dose and titrate AURYXIA.
                        </p>
                        <button className="cta hpp">
                            <span>GET DOSING INFORMATION</span>
                        </button>
                    </>
                }

                // callTwoClass="default"
                // callTwoLink={"/hyperphosphatemia/sign-up?request-rep=true"}

                // callTwoContent={
                // 	<>
                // 		<img src={CalloutIcon2} alt="" width="98" height="98" />
                // 		<h3>Stay connected with AURYXIA</h3>
                // 		<p>Whether you want to talk to a sales representative or order samples, we have you covered. Let us know what you&#x2019;re interested in and we&#x2019;ll be in touch.</p>
                // 		<button className="cta hpp"><span>GET STARTED</span></button>
                // 	</>
                // }
            />
        </div>
        <section id="crosslink-slab" className="akb akb_moa_section">
            <div
                className="flex-row"
                data-sal="zoom-in"
                data-sal-duration="600"
                data-sal-delay="200"
                data-sal-ease="ease-out-expo"
            >
                <div>
                    <img src={akbcares} alt="AkebiaCares" height="81" />
                    <h3>
                        Your partner in helping patients access the medication
                        they need
                    </h3>
                    <p className="boldTxt">
                        Connect with a personal Case Manager today!
                    </p>
                    <p className="med_width">
                        AkebiaCares personal Case Managers are standing by live
                        to answer your call and help your patients find
                        coverage.
                    </p>
                    <ul className="info_ul">
                        <li>
                            <a href="tel:18334253242">
                                1&#xFEFF;-&#xFEFF;833&#xFEFF;-&#xFEFF;4AKEBIA&nbsp;(425&#xFEFF;-&#xFEFF;3242)
                            </a>
                        </li>
                        <li>Monday&nbsp;-&nbsp;Friday</li>
                        <li className="hours_li">
                            8<span>AM</span>&nbsp;‐&nbsp;8<span>PM</span>
                            &nbsp;EST
                        </li>
                    </ul>

                    {/* <p>AURYXIA is on formulary with all major Medicare Part D Plans and on formulary with the majority of the commercial plans.<sup>3,4</sup></p> */}
                    <a
                        href="https://www.akebiacareshcp.com/"
                        className="cta akb"
                    >
                        <span>FIND SUPPORT OPTIONS</span>
                    </a>
                </div>
            </div>
        </section>
    </HPPLayout>
)

export default ResourcesPage
